import { Box, Flex } from '@stars-ecom/shared-atoms-ui'
import { AccountLayout, AccountPaymentCards } from '@stars-ecom/shared-organisms-ui'
import React from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const AccountPaymentCardsPage = (props) => {
  const { location } = props

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pb="15px"
      backgroundColor="#f6f3f1"
      w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Mes cartes bancaires - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
        />
        <title>{`Mes cartes bancaires - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <meta name="robots" content="noindex" />
        <script src="https://libs.hipay.com/js/sdkjs.js"></script>
        <link href="https://libs.hipay.com/themes/material.css" rel="stylesheet" />
      </Helmet>
      <AccountLayout>
        <Box h={{ md: '100%' }} backgroundColor="#fff">
          <AccountPaymentCards location={location} />
        </Box>
      </AccountLayout>
    </Flex>
  )
}

AccountPaymentCardsPage.pageType = 'account'

export default AccountPaymentCardsPage
